


































import { Vue, Component } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { getRole } from '@/api/global'
import { getName } from '@/utils/cookies'


@Component({
  name: "LoginContainer",
})
export default class LoginContainer extends Vue {
  formLogin = {
    userId: "",
    password: "",
  };

  async submit() {
    const result = await UserModule.login(this.formLogin);
    if (result) {
      const role = await getRole()
      const roleName = role[0]
      let userRole = {}
      if (roleName == 'manage') {
        userRole = {
          role: {
            rolecode: '001',
            rolename: 'admin'
          },
          username: getName()
        }
      } else {
        userRole = {
          role: {
            rolecode: '002',
            rolename: '普通用户'
          },
          username: getName()
        }
      }
      localStorage.setItem('current_user', JSON.stringify(userRole))
      const { redirect = "/" } = this.$route.query;
      this.$router.push(redirect as string);
    }
  }
}
